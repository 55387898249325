/*Form Stepper Styles*/
.MuiStepIcon-root {
  color: #3e739b !important;
}


.MuiStepIcon-root.MuiStepIcon-completed {
  color: #52b456 !important;
}


.MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

@media (max-width: 325px) {
    #messageComponent {
        overflow-y: scroll !important;
    };
}

@media (max-width: 930px) {
    #miniLogo {
        width: 10%;
        height: 3%;
    };
}

@media (max-width: 990px) {
    #speedDial {
        right: 0px;
        bottom: 40px;
    };
};
